import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Link from '../utils/link'
import Seo from '../components/seo'
import Estimator from '../components/estimator'
import RenderBlock from '../utils/render-block'

class PageTemplate extends Component {

  render() {
    let page = this.props.data.wpPage

    return (
      <>
        <Seo
          bodyClass={page.slug}
          {...page.seo}
        />
        <section className='breadcrumbs'>
          <div className='breadcrumbs__inner'>
            <ul>
              <li><Link to='/'>Home</Link></li>
              <li>{page.title}</li>
            </ul>
          </div>
        </section>
        { page.slug === 'kitchen-estimator' &&
          <Estimator estimator={page.estimator} />
        }
        { page.acf.components && page.acf.components.map((el, i) => RenderBlock(el.fieldGroupName, el, i, 'page'))  }
      </>
    )
  }
}

export const pageQuery = graphql`
  query ($id: String!) {
    wpPage(id: {eq: $id}) {
      title
      slug
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphType
        opengraphImage {
          altText
          localFile {
            childImageSharp {
              original {
                src
                width
                height
              }
            }
          }
        }
        schema {
          raw
        }
      }
      estimator {
        intro {
          title
          content
          buttons {
            button {
              text
              link
            }
          }
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 75
                  width: 1400
                  formats: [AUTO, WEBP, AVIF]
                  placeholder: DOMINANT_COLOR
                )
              }
            }
          }
        }
        layout {
          title
          description
          selections {
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 75
                    width: 500
                    formats: [AUTO, WEBP, AVIF]
                    placeholder: DOMINANT_COLOR
                  )
                }
              }
            }
            title
          }
        }
        cabinetry {
          title
          description
          selections {
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 75
                    width: 500
                    formats: [AUTO, WEBP, AVIF]
                    placeholder: DOMINANT_COLOR
                  )
                }
              }
            }
            title
            prices {
              galley
              island
              lShape
              oneWall
              peninsula
              uShape
            }
          }
        }
        benchtop {
          title
          description
          selections {
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 75
                    width: 500
                    formats: [AUTO, WEBP, AVIF]
                    placeholder: DOMINANT_COLOR
                  )
                }
              }
            }
            title
            prices {
              galley
              island
              lShape
              oneWall
              peninsula
              uShape
            }
          }
        }
        appliances {
          title
          description
          selections {
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 75
                    width: 500
                    formats: [AUTO, WEBP, AVIF]
                    placeholder: DOMINANT_COLOR
                  )
                }
              }
            }
            title
            prices {
              galley
              island
              lShape
              oneWall
              peninsula
              uShape
            }
          }
        }
        tradePricing {
          prices {
            galley
            island
            lShape
            oneWall
            peninsula
            uShape
          }
        }
        disclaimer
      }
      acf {
        components {
          ... on WpPage_Acf_Components_HeroBanner {
            fieldGroupName
            leftImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 75
                    width: 1200
                    formats: [AUTO, WEBP, AVIF]
                    placeholder: DOMINANT_COLOR
                  )
                }
              }
            }
            rightImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 75
                    width: 1200
                    formats: [AUTO, WEBP, AVIF]
                    placeholder: DOMINANT_COLOR
                  )
                }
              }
            }
            overlay {
              title
              button {
                text
                link
              }
              secondButton {
                text
                link
              }
            }
            title
            content
            button {
              text
              link
            }
          }
          ... on WpPage_Acf_Components_CircleImageItems {
            fieldGroupName
            title
            layout
            content
            items {
              title
              content
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      quality: 75
                      width: 200
                      formats: [AUTO, WEBP, AVIF]
                      placeholder: DOMINANT_COLOR
                    )
                  }
                }
              }
              link
              text
            }
          }
          ... on WpPage_Acf_Components_OurRange {
            fieldGroupName
            title
            items {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      quality: 75
                      width: 600
                      formats: [AUTO, WEBP, AVIF]
                      placeholder: DOMINANT_COLOR
                    )
                  }
                }
              }
              title
              link
            }
          }
          ... on WpPage_Acf_Components_PageTitle {
            fieldGroupName
            title
          }
          ... on WpPage_Acf_Components_HowItWorks {
            fieldGroupName
            title
            items {
              title
              content
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      quality: 75
                      width: 200
                      formats: [AUTO, WEBP, AVIF]
                      placeholder: DOMINANT_COLOR
                    )
                  }
                }
              }
            }
            buttons {
              button {
                text
                link
              }
            }
          }
          ... on WpPage_Acf_Components_WhoWeWorkWith {
            fieldGroupName
            title
            content
            items {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      quality: 75
                      width: 600
                      formats: [AUTO, WEBP, AVIF]
                      placeholder: DOMINANT_COLOR
                    )
                  }
                }
              }
              logo {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      quality: 75
                      width: 600
                      formats: [AUTO, WEBP, AVIF]
                      placeholder: DOMINANT_COLOR
                    )
                  }
                }
              }
              title
              socialHandle
              link
            }
          }
          ... on WpPage_Acf_Components_ReadyToGetStarted {
            fieldGroupName
          }
          ... on WpPage_Acf_Components_DesignConsultationHeader {
            fieldGroupName
            title
            content
            topics
            button {
              text
              link
            }
          }
          ... on WpPage_Acf_Components_Instagram {
            fieldGroupName
            leftTopImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 75
                    width: 320
                    formats: [AUTO, WEBP, AVIF]
                    placeholder: DOMINANT_COLOR
                  )
                }
              }
            }
            leftBottomImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 75
                    width: 320
                    formats: [AUTO, WEBP, AVIF]
                    placeholder: DOMINANT_COLOR
                  )
                }
              }
            }
            rightTopImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 75
                    width: 320
                    formats: [AUTO, WEBP, AVIF]
                    placeholder: DOMINANT_COLOR
                  )
                }
              }
            }
            rightBottomImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 75
                    width: 320
                    formats: [AUTO, WEBP, AVIF]
                    placeholder: DOMINANT_COLOR
                  )
                }
              }
            }
            overlay {
              title
              handle
            }
          }
          ... on WpPage_Acf_Components_FrequentlyAskedQuestions {
            fieldGroupName
            title
            questions {
              title
              content
            }
          }
          ... on WpPage_Acf_Components_ComparisonSlider {
            fieldGroupName
            title
            content
            items {
              beforeImage {
                localFile {
                  childImageSharp {
                    original {
                      src
                    }
                  }
                }
              }
              afterImage {
                localFile {
                  childImageSharp {
                    original {
                      src
                    }
                  }
                }
              }
            }
          }
          ... on WpPage_Acf_Components_DiyBenefits {
            fieldGroupName
            items {
              title
              content
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      quality: 75
                      width: 800
                      formats: [AUTO, WEBP, AVIF]
                      placeholder: DOMINANT_COLOR
                    )
                  }
                }
              }
              overlay {
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        quality: 75
                        width: 120
                        formats: [AUTO, WEBP, AVIF]
                        placeholder: DOMINANT_COLOR
                      )
                    }
                  }
                }
              }
              text
              link
              reverse
            }
          }
          ... on WpPage_Acf_Components_HowItWorksBlocks {
            fieldGroupName
            items {
              title
              content
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      quality: 75
                      width: 800
                      formats: [AUTO, WEBP, AVIF]
                      placeholder: DOMINANT_COLOR
                    )
                  }
                }
              }
              icon {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      quality: 75
                      width: 800
                      formats: [AUTO, WEBP, AVIF]
                      placeholder: DOMINANT_COLOR
                    )
                  }
                }
              }
              reverse
            }
          }
          ... on WpPage_Acf_Components_BasicPage {
            fieldGroupName
            title
            content
          }
          ... on WpPage_Acf_Components_TermsAndConditionsBlocks {
            fieldGroupName
            title
            items {
              title
              content
            }
          }
          ... on WpPage_Acf_Components_Gallery {
            fieldGroupName
            title
            items {
              panel {
                topLeftImage {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        quality: 75
                        width: 256
                        formats: [AUTO, WEBP, AVIF]
                        placeholder: DOMINANT_COLOR
                      )
                    }
                  }
                }
                topCentreImage {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        quality: 75
                        width: 600
                        formats: [AUTO, WEBP, AVIF]
                        placeholder: DOMINANT_COLOR
                      )
                    }
                  }
                }
                topRightImage {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        quality: 75
                        width: 256
                        formats: [AUTO, WEBP, AVIF]
                        placeholder: DOMINANT_COLOR
                      )
                    }
                  }
                }
                bottomLeftImage {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        quality: 75
                        width: 600
                        formats: [AUTO, WEBP, AVIF]
                        placeholder: DOMINANT_COLOR
                      )
                    }
                  }
                }
                bottomCentreImage {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        quality: 75
                        width: 256
                        formats: [AUTO, WEBP, AVIF]
                        placeholder: DOMINANT_COLOR
                      )
                    }
                  }
                }
                bottomRightImage {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        quality: 75
                        width: 600
                        formats: [AUTO, WEBP, AVIF]
                        placeholder: DOMINANT_COLOR
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default PageTemplate
