
import React, { Component } from 'react'
import Link from '../utils/link'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Tick from '../assets/images/selected.svg'
import Close from '../assets/images/close.svg'
import Kitchen from '../assets/images/kitchen-calculated.jpg'
import tag from "../utils/tag"

const camalize = (str) => {
  return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
}

class Estimator extends Component {

  state = {
    total: 0,
    contractorTotal: 0,
    popup: false,
    layout: {
      title: '',
    },
    cabinetry: {
      title: '',
    },
    benchtop:  {
      title: '',
    },
    appliances:  {
      title: '',
    },
    mailTo: '',
  }

  chooseOption = (state, value) => {
    let { layout } = this.state
    this.setState({ [state]: value })
  }

  calculateEstimate = () => {
    let { total, layout, cabinetry, benchtop, appliances, contractorTotal } = this.state
    total = cabinetry.prices[camalize(layout.title)] + benchtop.prices[camalize(layout.title)] + appliances.prices[camalize(layout.title)]
    contractorTotal = this.props.estimator.tradePricing.prices[camalize(layout.title)]
    let formattedBody = `Kitchen Layout: ${layout.title} \n Cabinetry Colour: ${cabinetry.title} \n Benchtop: ${benchtop.title} \n Appliances: ${appliances.title} \n\n Total Estimated Cost: $${total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} \n Contractor Cost: $${contractorTotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
    let
      mailToLink = "mailto:webquery@diyhome.com.au?subject=Kitchen Estimator Enquiry&body="+encodeURIComponent(formattedBody)
    this.setState({
      total,
      contractorTotal,
      popup: true,
      mailTo: mailToLink
    })
    tag("Form Submission", "Kitchen Estimator: Popup Shown")
  }



  clearOptions = () => {
    this.setState({
      layout: {
        title: '',
      },
      cabinetry: {
        title: '',
      },
      benchtop:  {
        title: '',
      },
      appliances:  {
        title: '',
      },
      total: 0,
      contractorTotal: 0,
      popup: false,
    })
  }

  render() {

    let { estimator } = this.props
    let { layout, cabinetry, benchtop, appliances, total, popup , contractorTotal, mailTo } = this.state

    return (
      <article className='estimator'>
        <section className='estimator__intro'>
          <div className='estimator__inner'>
            <h1>{estimator.intro.title}</h1>
            <div className='estimator__content' dangerouslySetInnerHTML={{ __html: estimator.intro.content }} />
            { estimator.intro.buttons &&
              <ul className='estimator__buttons'>
                {estimator.intro.buttons.map((el, i) => (
                  <li key={i}>
                    <Link to={el.button.link} className='btn'>{el.button.text}</Link>
                  </li>
                ))}
              </ul>
            }
            <GatsbyImage className='estimator__banner' loading='lazy' image={getImage(estimator.intro.image?.localFile)} alt={estimator.intro.title} />
          </div>
        </section>
        <section className='estimator__blocks' id='start'>
          <div className='estimator__inner'>
            <div className='estimator__block'>
              <div className='estimator__heading'>
                <h4>
                  <span>1</span>
                  {estimator.layout.title}
                </h4>
                <div className='estimator__description' dangerouslySetInnerHTML={{ __html: estimator.layout.description }} />
              </div>
              { estimator.layout.selections &&
                <ul className='estimator__layout'>
                  {estimator.layout.selections.map((el, i) => {
                    return (
                      <li key={i} className={layout.title === el.title ? 'active' : ''}>
                        <button
                          onClick={() => this.chooseOption('layout', el)}
                        >
                          <GatsbyImage loading='lazy' image={getImage(el.image?.localFile)} alt={el.title} />
                          <h4>{el.title}</h4>
                          {layout.title === el.title && <img className='tick' src={Tick} alt='Selected Option' /> }
                        </button>
                      </li>
                    )
                  })}
                </ul>
              }
            </div>
            <div className='estimator__block'>
              <div className='estimator__heading'>
                <h4>
                  <span>2</span>
                  {estimator.cabinetry.title}
                </h4>
                <div className='estimator__description' dangerouslySetInnerHTML={{ __html: estimator.cabinetry.description }} />
              </div>
              { estimator.cabinetry.selections &&
                <ul className='estimator__cabinetry'>
                  {estimator.cabinetry.selections.map((el, i) => (
                    <li key={i} className={cabinetry.title === el.title ? 'active' : ''}>
                      <button
                        onClick={() => this.chooseOption('cabinetry', el)}
                      >
                        <GatsbyImage loading='lazy' image={getImage(el.image?.localFile)} alt={el.title} />
                        <h4>{el.title}</h4>
                        {cabinetry.title === el.title && <img className='tick' src={Tick} alt='Selected Option' /> }
                      </button>
                    </li>
                  ))}
                </ul>
              }
            </div>
            <div className='estimator__block'>
              <div className='estimator__heading'>
                <h4>
                  <span>3</span>
                  {estimator.benchtop.title}
                </h4>
                <div className='estimator__description' dangerouslySetInnerHTML={{ __html: estimator.benchtop.description }} />
              </div>
              { estimator.benchtop.selections &&
                <ul className='estimator__benchtop'>
                  {estimator.benchtop.selections.map((el, i) => (
                    <li key={i} className={benchtop.title === el.title ? 'active' : ''}>
                      <button
                        onClick={() => this.chooseOption('benchtop', el)}
                      >
                        <GatsbyImage loading='lazy' image={getImage(el.image?.localFile)} alt={el.title} />
                        <h4>{el.title}</h4>
                        {benchtop.title === el.title && <img className='tick' src={Tick} alt='Selected Option' /> }
                      </button>
                    </li>
                  ))}
                </ul>
              }
            </div>
            <div className='estimator__block'>
              <div className='estimator__heading'>
                <h4>
                  <span>4</span>
                  {estimator.appliances.title}
                </h4>
                <div className='estimator__description' dangerouslySetInnerHTML={{ __html: estimator.appliances.description }} />
              </div>
              { estimator.appliances.selections &&
                <ul className='estimator__appliances'>
                  {estimator.appliances.selections.map((el, i) => (
                    <li key={i} className={appliances.title === el.title ? 'active' : ''}>
                      <button
                        onClick={() => this.chooseOption('appliances', el)}
                      >
                        <GatsbyImage loading='lazy' image={getImage(el.image?.localFile)} alt={el.title} />
                        <h4>{el.title}</h4>
                        <span>${el.prices.galley.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</span>
                        {appliances.title === el.title && <img className='tick' src={Tick} alt='Selected Option' /> }
                      </button>
                    </li>
                  ))}
                </ul>
              }
            </div>
            <div className='estimator__calculate'>
              <p>
                <button
                  className='btn btn--primary'
                  onClick={this.calculateEstimate}
                >
                  Get your estimate
                </button>
              </p>
              <p>
                <button
                  className='estimator__clear'
                  onClick={this.clearOptions}
                >
                  or start again
                </button>
              </p>
            </div>
          </div>
        </section>
        { popup &&
          <section className='estimator__overlay'>
            <div className='estimator__popup'>
              <button
                className='estimator__close'
                onClick={() => this.setState({popup: false})}
              >
                <img src={Close} alt='Close Estimate' />
              </button>
              <div className='estimator__popup-inner'>
                <h4>Your Kitchen Estimator</h4>
                <div className='estimator__popup-wrap'>
                  <div className='estimator__selections'>
                    <img src={Kitchen} alt='Selected Kitchen' />
                    <ul>
                      <li>
                        <span className='label'>Kitchen Layout</span>
                        <span className='value'>{layout.title}</span>
                      </li>
                      <li>
                        <span className='label'>Cabinetry Colour</span>
                        <span className='value'>{cabinetry.title}</span>
                      </li>
                      <li>
                        <span className='label'>Benchtop*</span>
                        <span className='value'>{benchtop.title}</span>
                      </li>
                      <li>
                        <span className='label'>Appliances*</span>
                        <span className='value'>{appliances.title}</span>
                      </li>
                    </ul>
                  </div>
                  <div className='estimator__totals'>
                    <ul>
                      <li>
                        <span className='label'>Total Estimated Cost*</span>
                        <span className='value'>
                          ${ total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') }
                          <span className='gst'>includes GST &amp; our best price guarantee</span>
                        </span>
                      </li>
                      <li>
                        <span className='label'>Contractor Cost*</span>
                        <span className='value'>
                          ${ contractorTotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') }
                          <span className='gst'>includes GST &amp; our best price guarantee</span>
                        </span>
                      </li>
                    </ul>
                    <div className='estimator__disclaimer' dangerouslySetInnerHTML={{ __html: estimator.disclaimer }} />
                    <Link className='btn btn--primary' to={mailTo} onClick={() => {
                      tag("Form Submission", "Kitchen Estimator: Email Us")
                    }}>Contact Us</Link>
                    <button
                      className='estimator__clear'
                      onClick={this.clearOptions}
                    >
                      Try another configuration
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        }
      </article>
    )
  }
}

export default Estimator
